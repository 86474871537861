<template>
    <span :class="['MyLabel', {[`MyLabel--${size}`]: size, 'MyLabel--border': border}]"
          :style="styles"
          @click="onClick"
          contenteditable="false"
    >
        <slot>{{ text }}</slot>
        <van-icon class="MyLabel__close" name="cross" v-if="closable" @click.stop="onClose" />
    </span>
</template>

<script>
    import {hexToRgb} from '@/utils/tools';

    export default {
        name: "MyLabel",
        props: {
            // 文本, 可选slot分发方式
            text: [String, Number],
            // 颜色
            color: {
                type: String,
                default: ''
            },
            // 背景透明度
            alpha: {
                type: Number,
                default: 0.3
            },
            // 背景色，默认虚化字体颜色作为背景色
            background: {
                type: String,
                default: ''
            },
            // 尺寸: medium, small, mini
            size: String,
            // 关闭按钮
            closable: Boolean,
            // 边框
            border: Boolean,
            clickable: Boolean,
        },
        data() {
            return {
                hexToRgb
            }
        },
        computed: {
            fontColor() {
                return this.color || '#B2B4B8';
            },
            bgColor() {
                if (this.background) return this.background;
                return this.color ? this.hexToRgb(this.color, this.alpha) : '#FFFFFF';
            },
            // 样式变量
            styles() {
                return {
                    '--color': this.fontColor,
                    '--background': this.bgColor,
                    '--border': hexToRgb(this.fontColor),
                    cursor: this.clickable ? 'pointer' : 'default'
                }
            }
        },
        methods: {
            onClick(event) {
                if (this.clickable) this.$emit('click', event);
            },
            onClose(event) {
                this.$emit('close', event);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .MyLabel {
        //cursor: pointer;
        background: var(--background);
        color: var(--color);
        height: 28px;
        display: inline-block;
        line-height: 28px;
        //margin-right: 10px;
        font-size: 14px;
        border-radius: 2px;
        box-sizing: border-box;
        padding: 0 10px;
        white-space: nowrap;
        &:last-child {
            margin-right: 0;
        }
        &__close {
            border-radius: 50%;
            text-align: center;
            position: relative;
            cursor: pointer;
            font-size: 12px;
            height: 16px;
            width: 16px;
            line-height: 16px;
            vertical-align: middle;
            top: -1px;
            right: -5px;
            transform: scale(.8);
            &:before {
                display: block;
            }
            &:hover {
                color: #FFFFFF;
                background: var(--color);;
            }
        }
    }
    .MyLabel--border {
        border: 1px solid var(--border);
    }
    .MyLabel--medium.MyLabel--border {
        line-height: 24px;
    }
    .MyLabel--small.MyLabel--border {
        line-height: 22px;
    }
    .MyLabel--mini.MyLabel--border {
        line-height: 20px;
    }
    .MyLabel--medium {
        height: 26px;
        line-height: 26px;
        font-size: 14px;
    }
    .MyLabel--small {
        font-size: 12px;
        height: 24px;
        padding: 0 8px;
        line-height: 24px;
    }
    .MyLabel--mini {
        font-size: 12px;
        height: 22px;
        padding: 0 5px;
        line-height: 22px;
        .MyLabel__close {
            transform: scale(.7);
        }
    }
</style>
