<template>
    <div :style="{'--DEFAULT_HEIGHT': `${DEFAULT_HEIGHT}px`}" class="MyList">
        <van-overlay :show="isFold" :z-index="2" @click="isFold = false" />
        <van-sticky :style="`height: ${offsetTop}px`">
            <div v-if="$scopedSlots.selectQuery"
                 :class="['MyList__select', {overlay: isFold}]"
            >
                <van-form ref="form" class="MyList__select--form" colon>
                    <slot :query="config.query" name="selectQuery"></slot>
                </van-form>
                <div v-if="queryText.length > 0" class="MyList__select--text">
                    <my-label v-for="(text, index) in queryText"
                              :key="index"
                              background="#FFFFFF"
                              border
                              color="#101010"
                              size="small"
                    >{{ text }}</my-label>
                </div>
                <div class="MyList__select--action">
                    <van-button :disabled="loading" block size="small" style="margin-right: 16px;" type="default" @click="onReset">重置</van-button>
                    <van-button :loading="loading" block size="small" type="info" @click="onSearch">快速查询</van-button>
                </div>
                <div class="MyList__select--fold" @click="isFold = !isFold">
                    <span>{{ isFold ? '收起' : '展开' }}筛选 <van-icon :class="{reverse: isFold}" name="arrow-down" /></span>
                </div>
            </div>
        </van-sticky>
        <div class="MyList__list">
            <van-sticky :offset-top="offsetTop" :z-index="1">
                <div class="MyList__list--divider"></div>
                <my-tab v-if="(tabItems.length > 0) || sortOptions.length > 0"
                        v-model="tabActive"
                        :items="tabItems"
                        @change="onTabChange"
                >
                    <my-select v-if="sortOptions.length > 0"
                               slot="extra"
                               v-model="sort"
                               :options="sortOptions"
                               position="bottom-end"
                               @change="onSearch"
                    />
                </my-tab>
            </van-sticky>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list v-model="loading"
                          :finished="finished"
                          finished-text="没有更多了"
                          @load="onLoad"
                >
                    <div v-for="(row, index) in list" :key="index" class="MyList__list--item" @click="onSelect(row, index)">
                        <div class="MyList__list--item__title">
                            <slot :row="row" name="title">
                                <div class="title--text">
                                    <slot :row="row" name="title--text"></slot>
                                    <div class="tags">
                                        <slot :row="row" name="title--tags"></slot>
                                    </div>
                                </div>
                                <div class="title--right">
                                    <slot :row="row" name="title--right"></slot>
                                </div>
                            </slot>
                        </div>
                        <div class="MyList__list--item__content">
                            <my-list-row :rows="config.columns">
                                <template #value="{column}">
                                    <!-- 具名插槽，以备自定义翻译列 -->
                                    <slot :name="column.prop" :row="row">
                                        {{ row[column.prop] }}
                                    </slot>
                                </template>
                            </my-list-row>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    const OFFSET_TOP = 172;
    const DEFAULT_HEIGHT = OFFSET_TOP - 82;
    import MyListRow from "./row";
    import MyTab from "../tab";
    import MySelect from "../select";
    import MyLabel from "../label";

    export default {
        name: "MyList",
        props: {
            config: {
                type: Object,
                required: true
            }
        },
        components: {
            MyListRow,
            MyTab,
            MySelect,
            MyLabel
        },
        data() {
            return {
                isFold: false,
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                tabActive: 0,
                OFFSET_TOP,
                DEFAULT_HEIGHT,
                pageNum: 0,
                pageSize: 20,
                sort: '',
                indexes: null,
                queryText: [],
            }
        },
        computed: {
            offsetTop() {
                let num = OFFSET_TOP;
                if (this.queryText.length > 0) {
                    return num + 34;
                }
                return num;
            },
            // 处理参数
            query() {
                // 过滤空值
                let query = JSON.parse(JSON.stringify(this.config.query));
                Object.keys(query).forEach(key => {
                    if (!query[key]) this.$delete(query, key);
                });
                // tab切换时的参数
                if (this.currentTab) {
                    let {tabs: {name}} = this.config;
                    let {value, api} = this.currentTab;
                    if (!api) query[name] = value;
                }
                // 排序参数
                if (this.sortOptions.length > 0 && this.sort) {
                    let {sort: {name}} = this.config;
                    query[name] = this.sort;
                }
                return {
                    ...query,
                    pageSize: this.pageSize,
                    pageNum: this.pageNum
                };
            },
            // 当前选中tab
            currentTab() {
                let tabItems = this.tabItems;
                if (tabItems.length > 0) {
                    return tabItems.find(o => o.value === this.tabActive);
                }
                return undefined;
            },
            // 切换tabs中的自定义api
            defaultApi() {
                let {api} = this.config;
                if (this.currentTab) {
                    let {api: tabApi} = this.currentTab || {};
                    return tabApi || api;
                } else {
                    return this.config.api;
                }
            },
            // tab切换
            tabItems() {
                let {tabs} = this.config || {};
                if (tabs) {
                    if (tabs && tabs.items) return tabs.items;
                }
                return [];
            },
            // 排序
            sortOptions() {
                let {sort} = this.config || {};
                if (sort) {
                    if (sort && sort.options) return sort.options;
                }
                return [];
            }
        },
        mounted() {
            this.getTabDefault();
        },
        methods: {
            // 动态更新当前行数据
            hotUpdate(row = null) {
                if (this.indexes) {
                    if (row) {
                        this.list.splice(this.indexes, 1, row);
                    } else {
                        this.list.splice(this.indexes, 1);
                    }
                }
            },
            // todo 删除一行
            hotDelete() {
                this.hotUpdate();
                this.$nextTick(() => {
                    if (this.list.length === 0) this.pageNum = 0;
                });
            },
            // 获取排序候选项及设置默认排序参数
            getTabDefault() {
                let tabItems = this.tabItems;
                if (tabItems.length > 0) {
                    let [tab] = tabItems;
                    this.tabActive = tab.value;
                }
                // 默认排序
                let sortOptions = this.sortOptions;
                if (sortOptions.length > 0) {
                    let [sort] = sortOptions;
                    this.sort = sort.value;
                }
            },
            // 加载数据
            onLoad() {
                this.indexes = null;
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                let {beforeSearch} = this.config;
                if (!this.defaultApi) return;
                let query = JSON.parse(JSON.stringify(this.query));
                if (beforeSearch) query = beforeSearch(query);

                this.defaultApi(query).then(res => {
                    let {list, pages} = res.data;
                    this.list = [...this.list, ...list];
                    this.loading = false;
                    // 如果总页数 = 当前页数，说明已经加载完了
                    if (pages === this.pageNum) {
                        this.finished = true;
                        // 总页数 > 当前页数，pageNum + 1
                    } else if (pages > this.pageNum) {
                        this.pageNum++;
                    }
                });
            },
            // 下拉刷新
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.pageNum = 0;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
            onTabChange(item) {
                this.onSearch();
                this.$emit('tab-change', item);
            },
            // 点击搜索
            onSearch() {
                this.refreshing = true;
                this.isFold = false;
                this.onRefresh();
                this.getQueryText();
            },
            getQueryText() {
                this.$nextTick(() => {
                    if (this.$refs.form) {
                        let {$children: children} = this.$refs.form;
                        let queryText = [];
                        if (children.length > 0) {
                            children.slice(2).forEach(e => {
                                let {value, inputText, startValue, endValue} = e;
                                // 像时间区间之类的控件，需要取 startValue 和 endValue
                                if (typeof startValue !== 'undefined' && typeof endValue !== 'undefined') {
                                    // 区间
                                    if (startValue && endValue) {
                                        queryText.push(`${startValue} - ${endValue}`);
                                    } else if (startValue) { // 大于
                                        queryText.push(`>= ${startValue}`);
                                    } else if (endValue) { // 小于
                                        queryText.push(`<= ${endValue}`);
                                    }
                                } else if (typeof inputText !== 'undefined') {
                                    if (inputText) queryText.push(inputText);
                                } else if (value) { // 默认取value
                                    queryText.push(value);
                                }
                            });
                        }
                        this.queryText = queryText;
                    }
                });
            },
            // 重置搜索参数
            onReset() {
                let query = this.config.query;
                Object.keys(query).forEach(key => query[key] = Array.isArray(query[key]) ? [] : '');
                this.onSearch();
            },
            onSelect(item, index) {
                this.indexes = index;
                this.$emit('select', item);
            },
        },
    }
</script>

<style lang="scss" scoped>
    .MyList {
        &__select {
            background: #FFFFFF;
            z-index: 2;
            position: relative;
            &.overlay {
                .MyList__select--form {
                    height: auto;
                }
            }
            &--form {
                height: var(--DEFAULT_HEIGHT);
                overflow: hidden;
                padding: 5px 16px;
                ::v-deep {
                    .van-field {
                        padding: 5px 0;
                        &__label {
                            padding: 5px 10px 5px 0;
                            max-width: 70px;
                        }
                        &__value {
                            background: #F5F6F8;
                            padding: 5px 10px;
                        }
                    }
                }
            }
            &--text {
                padding: 0 16px 10px 16px;
                overflow-x: auto;
                overflow-y: hidden;
                white-space: nowrap;
            }
            &--action {
                display: flex;
                padding: 0 16px;
            }
            &--fold {
                font-size: 12px;
                padding: 8px 0;
                line-height: 24px;
                text-align: center;
                .van-icon-arrow-down {
                    transition: transform .3s;
                    transform: rotateZ(0deg);
                    &.reverse {
                        transform: rotateZ(180deg);
                    }
                }
            }
        }
        &__list {
            &--divider {
                height: 10px;
                background: #F5F6F8;
            }
            ::v-deep {
                .van-list {
                    padding: 0 16px;
                }
            }
            &--item {
                font-size: 14px;
                border-bottom: 1px solid #ECEEF1;
                padding: 16px 0;
                text-align: left;
                &:last-child {
                    border-bottom: none;
                }
                &__title {
                    display: flex;
                    justify-content: space-between;
                    .title--text {
                        font-weight: 600;
                        .tags {
                            margin: 0 10px;
                            display: inline-block;
                            ::v-deep > * {
                                margin-right: 5px;
                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    .title--right {
                        font-size: 12px;
                        color: #1677FF;
                    }
                }
                &__content {
                    background: #F5F6F8;
                    border-radius: 6px;
                    margin-top: 10px;
                    padding: 10px 16px;
                }
            }
        }
    }
</style>
