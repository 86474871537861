<template>
    <fragment>
        <div v-if="input">
            <van-field v-model="inputText"
                       :border="false"
                       :label="label"
                       :placeholder="placeholder"
                       readonly
                       @click="showPopup"
            >
                <i slot="right-icon" :class="['iconfont', 'icon--xialajiantou', {reverse: show}]"></i>
            </van-field>
            <my-drawer v-model="show" :closable="fullscreen" :position="position" :style="popupStyle" :title="placeholder" @close="closePopup">
                <div class="MySelectPopup">
                    <div v-for="(v, index) in options"
                         :key="index"
                         :class="['MySelectPopup__option', {active: v.value === (active || value)}]"
                         @click="onSelect(v)"
                    >
                        <div class="MySelectPopup__option--item__text">{{ v.text }}</div>
                        <van-icon v-if="v.value === (active || value)" class="MySelectPopup__option--item__icon" name="success" />
                    </div>
                </div>
                <template v-if="fullscreen" #footer>
                    <van-button block type="default" @click="onReset">重置</van-button>
                    <van-button block type="info" @click="onConfirm">确定</van-button>
                </template>
            </my-drawer>
        </div>
        <van-popover v-else
                     v-model="show"
                     :actions="options"
                     :placement="position"
                     trigger="click"
                     @select="onSelect"
        >
            <span slot="reference" class="MySelectText">
                {{ inputText || '请选择' }} <i :class="['iconfont', 'icon--xialajiantou', {reverse: show}]"></i>
            </span>
        </van-popover>
    </fragment>
</template>

<script>
    import {Fragment} from 'vue-fragment';
    import MyDrawer from "../drawer";

    export default {
        name: "MySelect",
        components: {
            Fragment,
            MyDrawer
        },
        props: {
            // 绑定值
            value: {
                type: [String, Number],
                required: true
            },
            // 候选项 [{text: 'xxx', value: 'xxxx'}, ...]
            options: Array,
            // 输入框样式
            input: Boolean,
            // 弹出位置 top, bottom, left, right, ...
            position: String,
            // 全屏
            fullscreen: Boolean,
            // 占位文字
            placeholder: {
                type: String,
                default: ''
            },
            // input模式下的标签名称
            label: String,
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        data() {
            return {
                inputText: '',
                show: false,
                active: null
            }
        },
        watch: {
            value: {
                handler() {
                    this.setInputValue();
                }
            }
        },
        computed: {
            popupStyle() {
                if (this.fullscreen) {
                    return {height: '100%', width: '100%'};
                } else {
                    if (['left', 'right'].includes(this.position)) {
                        return {height: '100%', width: '50%'};
                    }
                    return {height: '50%', width: '100%'};
                }
            }
        },
        mounted() {
            this.setInputValue();
        },
        methods: {
            setInputValue() {
                if (this.value) {
                    if (this.value === this.active) return;
                    let {text = '', value = ''} = this.options.find(v => v.value === this.value) || {};
                    this.inputText = text;
                    this.active = value;
                } else {
                    this.inputText = '';
                    this.active = null;
                }
            },
            showPopup() {
                this.show = true;
                // 默认选中第一个
                if (this.options.length > 0 && this.input) {
                    let [option] = this.options;
                    this.active = option.value;
                } else {
                    this.active = null;
                }
            },
            closePopup() {
                this.show = false;
                this.active = null;
            },
            // 选中
            onSelect(item) {
                if (this.fullscreen) {
                    this.active = item.value;
                } else {
                    this.changeValue(item);
                }
            },
            onReset() {
                this.$emit('change', '');
                this.inputText = '';
                this.active = null;
            },
            // 确认选择
            onConfirm() {
                let item = this.options.find(v => v.value === this.active);
                if (item) this.changeValue(item);
                this.show = false;
            },
            // 同步value
            changeValue(item) {
                let {text, value} = item;
                this.$emit('change', value);
                this.inputText = text;
                this.show = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .MySelectText {
        cursor: pointer;
        white-space: nowrap;
    }
    .icon--xialajiantou {
        font-size: xx-small;
        transition: transform .3s;
        transform: rotateZ(0deg);
        display: inline-block;
        &.reverse {
            transform: rotateZ(180deg);
        }
    }
    .MySelectPopup {
        padding: 0 6px;
        &__option {
            padding: 10px;
            border-bottom: 1px solid #ECEEF1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            &__text {
                flex: 1;
            }
            &:last-child {
                border-bottom: none;
            }
            &.active {
                color: #1677FF;
                .MySelectPopup__option--item__icon {
                    color: #4AD07E;
                }
            }
        }
    }
</style>
