<template>
    <van-popup :class="['MyDrawer', {'MyDrawer--fullscreen': fullscreen}]"
               :style="{width: width}"
               v-bind="$attrs"
               v-on="$listeners"
               @click-overlay="onClose"
    >
        <div class="MyDrawer__container">
            <slot v-if="withNav" name="nav">
                <van-nav-bar :border="false"
                             :left-arrow="navLeftArrow"
                             :left-text="navLeftText"
                             :right-text="navRightText"
                             :title="title"
                             :z-index="0"
                             class="MyDrawer__nav"
                             @click-left="onClickLeft"
                >
                    <slot slot="title" name="nav-title">{{ title }}</slot>
                    <slot slot="right" name="nav-right">
                        <my-link @click="onClickRight">{{ navRightText }}</my-link>
                    </slot>
                </van-nav-bar>
            </slot>
            <div class="MyDrawer__body">
                <slot></slot>
            </div>
            <div v-if="$slots.footer" class="MyDrawer__footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </van-popup>
</template>

<script>
    import MyLink from "../link";

    export default {
        name: "MyDrawer",
        components: {MyLink},
        props: {
            // 标题
            title: String,
            // 导航栏左侧文字
            navLeftText: String,
            // 导航栏右侧文字
            navRightText: String,
            // 左侧箭头
            navLeftArrow: {
                type: Boolean,
                default: true
            },
            // 宽度，百分比
            width: String,
            // 全屏
            fullscreen: {
                type: Boolean,
                default: true
            },
            withNav: {
                type: Boolean,
                default: true
            }
        },
        model: {
            prop: 'value',
            event: 'close'
        },
        methods: {
            onClose() {
                this.$emit('close', false);
            },
            // 导航栏左侧箭头点击事件
            onClickLeft(event) {
                this.$emit('click-nav-left', event);
            },
            // 导航栏右侧按钮点击事件
            onClickRight(event) {
                this.$emit('click-nav-right', event);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .MyDrawer {
        &__container {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        &--fullscreen {
            height: 100%;
        }
        &__body {
            color: #101010;
            flex: 1;
            overflow-x: hidden;
            overflow-y: auto;
            height: 100%;
        }
        &__footer {
            display: flex;
            padding: 16px;
            ::v-deep .van-button + .van-button {
                margin-left: 16px;
            }
        }
    }
    //::v-deep {
    //    .van-nav-bar__title {
    //        margin-top: 20px;
    //        max-width: 90%;
    //        white-space: break-spaces;
    //    }
    //}
</style>
