<template>
    <div class="MyTab">
        <ul class="MyTab__wrap">
            <li v-for="(tab, index) in items"
                :key="index"
                :class="['MyTab__wrap--item', {active: tab[props.value] === value}]"
                @click="tabClick(tab)"
            >{{ tab[props.text] }}</li>
        </ul>
        <div v-if="$slots.extra" class="MyTab__extra">
            <slot name="extra"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MyTab",
        props: {
            value: [String, Number],
            items: Array,
            props: {
                type: Object,
                default() {
                    return {
                        text: 'text',
                        value: 'value'
                    }
                }
            }
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        methods: {
            // 点击tab
            tabClick(item) {
                const value = item[this.props.value];
                if (value === this.value) return;
                this.setActive(value);
                this.$emit('change', item);
            },
            // 设置当前选中
            setActive(value) {
                this.$emit('input', value);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .MyTab {
        height: 40px;
        font-size: 14px;
        border-top: 1px solid #ebedf0;
        border-bottom: 1px solid #ebedf0;
        display: flex;
        background: #FFFFFF;
        &__wrap {
            flex: 1;
            list-style-type: none;
            display: flex;
            height: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            &::-webkit-scrollbar {
                display: none;
            }
            &--item {
                padding: 0 16px;
                color: #101010;
                cursor: pointer;
                height: 100%;
                display: flex;
                align-items: center;
                &:after {
                    transition: all 0.3s ease-in-out;
                    content: "";
                }
                &.active {
                    transition: all 0.3s ease-in-out;
                    color: #1677FF;
                    position: relative;
                    &:after {
                        content: "";
                        height: 3px;
                        width: calc(100% - 32px);
                        background: #1677FF;
                        position: absolute;
                        bottom: 0;
                        left: 16px;
                    }
                }
            }
        }
        &__extra {
            display: flex;
            align-items: center;
            margin: 0 16px;
        }
    }
</style>
