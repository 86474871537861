<template>
    <a :class="['MyLink', {disabled}]" :href="disabled ? null : href" @click="handleClick">
        <slot></slot>
    </a>
</template>

<script>
    export default {
        name: "MyLink",
        inheritAttrs: false,
        props: {
            href: String,
            disabled: Boolean
        },
        methods: {
            handleClick(event) {
                if (!this.disabled && !this.href) this.$emit('click', event);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .MyLink {
        font-size: 14px;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        color: #1D69FB;
        user-select: none;
        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
</style>
