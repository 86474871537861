<template>
    <table class="MyListRow">
        <tr v-for="(row, index) in listRows" :key="index" class="MyListRow__item">
            <template v-for="column in row">
                <th :key="`${column.prop}--text`" class="MyListRow__item--text">{{ column.text }}</th>
                <td :key="`${column.prop}--value`" :colSpan="column.span * 2 - 1" class="MyListRow__item--value">
                    <!-- 声明一个插槽，由父级返回对应的值 -->
                    <slot :column="column" name="value">{{ column.prop }}</slot>
                </td>
            </template>
        </tr>
    </table>
</template>

<script>
    export default {
        name: "MyListRow",
        props: {
            // 列数据集合
            rows: {
                type: Array,
                required: true
            },
            // 栏数
            column: {
                type: Number,
                default: 2
            }
        },
        computed: {
            // 处理数据格式
            listRows() {
                if (this.rows.length === 0) return [];
                const rows = this.rows;
                const length = rows.length;
                // 栏数
                let column = this.column;
                let children = [];
                let result = [];
                rows.forEach((item, index) => {
                    // 处理column与span之间的关系
                    if (column <= (item.span || 1) || index === (length - 1)) {
                        // 剩余的列数小于设置的span个数, 或者最后一行的最后一列。自动补全，避免最后一列出现残缺的情况
                        item.span = column;
                        column = 0;
                    } else {
                        column -= item.span || 1;
                        }
                    children.push({span: item.span || 1, ...item});

                    if (column <= 0) {
                        column = this.column;
                        if (children.length > 0) result[result.length] = children;
                        children = [];
                    }
                    // 最后一行
                    if (length % this.column < this.column && index === (length - 1)) {
                        if (children.length > 0) result[result.length] = children;
                    }
                });

                return result;
            }
        },
    }
</script>

<style lang="scss" scoped>
    .MyListRow {
        border-collapse: collapse;
        &__item {
            font-size: 12px;
            &--text, &--value {
                padding: 5px;
            }
            &--text {
                text-align: right;
                color: #8F9295;
                font-weight: 600;
            }
            &--value {
                color: #101010;
            }
        }
    }
</style>
